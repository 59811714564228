<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-form>
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label-for="trainingSystemId"
                  >
                    <v-select
                      v-model="filter.trainingSystemId"
                      :options="trainingSystems"
                      :reduce="option => option.value"
                      placeholder="Hệ đào tạo"
                      @input="onTrainingSystemsChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label-for="courseId"
                  >
                    <v-select
                      v-model="filter.courseId"
                      :options="courses"
                      :reduce="option => option.value"
                      placeholder="Khóa học"
                      @input="onCoursesChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label-for="courseSemesterId"
                  >
                    <v-select
                      v-model="filter.courseSemesterId"
                      :options="courseSemesters"
                      :reduce="option => option.value"
                      placeholder="Học kỳ"
                      @input="onCourseSemesterChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label-for="departmentId"
                  >
                    <v-select
                      v-model="filter.departmentId"
                      :options="departments"
                      :reduce="option => option.value"
                      placeholder="Khoa/Bộ môn"
                      @input="onDepartmentChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label-for="majorId"
                  >
                    <v-select
                      v-model="filter.majorId"
                      :options="majors"
                      :reduce="option => option.value"
                      placeholder="Ngành học"
                      @input="onMajorsChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label-for="programmeId"
                  >
                    <v-select
                      id="programmeId"
                      v-model="filter.programmeId"
                      :options="programmes"
                      :reduce="option => option.value"
                      placeholder="Chương trình ĐT"
                      @input="onProgrammesChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label-for="classId"
                    title="Lớp độc lập"
                  >
                    <v-select
                      id="classId"
                      v-model="filter.classId"
                      :options="classes"
                      :reduce="option => option.value"
                      placeholder="Lớp độc lập"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label-for="code"
                    title="Mã sinh viên"
                  >
                    <b-form-input
                      id="code"
                      v-model="filter.code"
                      name="name"
                      placeholder="Mã sinh viên"
                      @keyup.enter="onSearchMainData"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <div class="text-right">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      class="ml-1"
                      @click="onSearchMainData('addStudent')"
                    >
                      <feather-icon icon="FilterIcon" /> Thêm sinh viên
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="ml-1"
                      @click="onSearchMainData"
                    >
                      <feather-icon icon="FilterIcon" /> Xem danh sách
                    </b-button>
                    <b-button
                      v-if="studentSuspendUpdatable"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="warning"
                      class="ml-1"
                      @click="onApproveSuspend(-1)"
                    >
                      <feather-icon icon="BellOffIcon" /> Bỏ qua
                    </b-button>
                    <b-button
                      v-if="studentSuspendUpdatable"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="danger"
                      class="ml-1"
                      @click="onApproveSuspend(1)"
                    >
                      <feather-icon icon="XOctagonIcon" /> Cho thôi học
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      class="ml-1"
                      @click="exportExcel"
                    >
                      <span class="text-nowrap text-right">
                        <feather-icon icon="DownloadIcon" /> Xuất excel
                      </span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-form>
            <b-row class="mt-0">
              <b-col cols="12">
                <div class="d-flex align-items-center mb-1 mt-1">
                        <span class="text-nowrap"> Hiển thị {{filter.itemsPerPage * (filter.currentPage-1) +1}}
                          đến {{(filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage)}} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                </div>
                <vue-good-table
                  mode="remote"
                  class="my-table"
                  style-class="vgt-table striped bordered"
                  row-style-class="vgt-row"
                  :columns="columns"
                  :rows="mainData"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  :select-options="{ enabled: true, selectOnCheckboxOnly: true, selectionText: 'SV được chọn', clearSelectionText: 'Bỏ chọn' }"
                  @on-selected-rows-change="selectionChanged"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span
                      v-if="props.column.field === 'status'"
                      :class="(props.row.status === 1) ? 'text-danger font-weight-bold' : 'text-warning'"
                    >
                      {{ getStatusName(props.row.status) }}
                    </span>
                    <span
                      v-else-if="props.column.field === 'action'"
                      class="thead-group-action"
                    >
                      <b-button-group>
                        <b-button
                          :disabled="(props.row.status <= -1)"
                          variant="danger"
                          class="btn-icon"
                          size="sm"
                          title="Xóa"
                          @click="onDeleteMainData(props.row)"
                        >
                          <feather-icon icon="TrashIcon" />
                        </b-button>
                      </b-button-group>
                    </span>
                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                          đến {{ (filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BContainer,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
  BForm,
  BFormGroup,
  BButtonGroup, BFormInput,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { getUser } from '@/auth/utils'
import { hasPermissionForResource } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import * as XLSX from 'xlsx'

export const Attr = Object.freeze({
  store: 'studentSuspend',
})
export default {
  name: 'SuspendByClass',
  directives: {
    Ripple,
  },
  components: {
    BFormInput,
    BButton,
    BButtonGroup,
    BCard,
    BRow,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    VueGoodTable,
    BFormGroup,
    BForm,
    vSelect,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        organizationId: getUser().orgId,
        trainingSystemId: null,
        courseId: null,
        courseSemesterId: null,
        departmentId: null,
        majorId: null,
        programmeId: null,
        classId: null,
        code: null,
        name: null,
        sort: null,
        status: null,
      },
      currentMainData: undefined,
      extMainData: {
        planClassNum: 1,
        planClassData: [],
        planStudentClassData: [],
      },
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 100],
      currentName: '',
      currentCode: '',
      selectedMainData: [],
    }
  },
  computed: {
    ...mapGetters({
      mainData: `${Attr.store}/dataLists`,
      totalRows: `${Attr.store}/totalRows`,
      statuses: `${Attr.store}/statuses`,
      resourceName: `${Attr.store}/resourceName`,
      courses: 'dropdown/courses',
      courseSemesters: 'dropdown/courseSemesters',
      trainingSystems: 'dropdown/trainingSystems',
      departments: 'dropdown/departments',
      majors: 'dropdown/majors',
      programmes: 'dropdown/programmes',
      classes: 'dropdown/classes',
    }),
    statusFilterDropdownItems() {
      return this.statuses.map(item => ({ value: item.value, text: item.label }))
    },
    columns() {
      return [
        {
          label: 'Mã sinh viên',
          field: 'code',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Họ tên',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Lớp cố định',
          field: 'className',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Chương trình ĐT',
          field: 'programmeName',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'TBC học kỳ',
          field: 'gpaFourth',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'TBC tích lũy',
          field: 'cgpaFourth',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Xử lý',
          field: 'status',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: this.statusFilterDropdownItems,
            placeholder: 'Tất cả',
          },
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Thao tác',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        },
      ]
    },
    studentSuspendUpdatable() {
      return hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.STUDENT_SUSPEND)
    },
  },
  async created() {
    this.updateParams({ organizationId: this.user.orgId })
    this.isLoading = true
    try {
      await Promise.all([
        this.getTrainingSystems(this.filter),
        this.getDepartments(this.filter),
      ])
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getMainData: `${Attr.store}/getData`,
      getTrainingSystems: 'dropdown/getTrainingSystems',
      getCourses: 'dropdown/getCourses',
      getCourseSemesters: 'dropdown/getCourseSemesters',
      getDepartments: 'dropdown/getDepartments',
      getMajors: 'dropdown/getMajors',
      getProgrammes: 'dropdown/getProgrammes',
      getClasses: 'dropdown/getClasses',
      createPlanData: `${Attr.store}/createPlanData`,
      updateData: `${Attr.store}/updateData`,
    }),
    async onTrainingSystemsChange(event) {
      this.filter.courseId = null
      await this.getCourses({
        organizationId: getUser().orgId,
        trainingSystemId: event,
      })
    },
    async onCoursesChange() {
      // Loc lai CTDT, Lop doc lap
      const request = { ...this.filter }
      request.status = 1
      request.currentPage = 1
      request.itemsPerPage = 100
      request.organizationId = getUser().orgId
      await this.getProgrammes(request)
      await this.getCourseSemesters(request)
      await this.getClasses(request)
    },
    async onCourseSemesterChange() {
      // Loc lai CTDT, Lop doc lap
      const request = { ...this.filter }
      request.status = 1
      request.currentPage = 1
      request.itemsPerPage = 100
      request.organizationId = getUser().orgId
      this.filter.programmeId = null
      await this.getProgrammes(request)
    },
    async onDepartmentChange(event) {
      this.filter.majorId = null
      this.filter.programmeId = null
      await this.getMajors({
        organizationId: getUser().orgId,
        departmentId: event,
      })
    },
    async onMajorsChange() {
      this.filter.programmeId = null
      const request = { ...this.filter }
      request.status = 1
      request.currentPage = 1
      request.itemsPerPage = 100
      request.organizationId = getUser().orgId
      await this.getProgrammes(request)
    },
    async onProgrammesChange() {
      this.filter.classId = null
      const request = { ...this.filter }
      request.status = 1
      request.currentPage = 1
      request.itemsPerPage = 100
      request.organizationId = getUser().orgId
      await this.getClasses(request)
    },
    onCreateMainData() {
      this.currentMainData = undefined
      this.extMainData = {}
    },
    onEditMainData(mainData) {
      this.isCopy = false
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = mainData
      this.currentMainData = rest
    },
    onDeleteMainData(mainData) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa ${this.resourceName.fullName}? <span class="text-danger">${mainData.name}</span>`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            this.selectedMainData = []
            const request = {
              studentCgpaIds: [{ studentCgpaId: mainData.id }],
              status: -1,
            }
            const response = await this.updateData(request)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                await this.getMainDataFromStore()
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    selectionChanged(params) {
      this.selectedMainData = params ? params.selectedRows.map(e => ({ studentCgpaId: e.id })) : []
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getMainDataFromStore()
    },
    async getMainDataFromStore() {
      this.isLoading = true
      try {
        await this.getMainData(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getMainDataFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        const selectedColumn = this.columns.findIndex(e => e.field === field)

        this.updateParams({ sort: `${selectedColumn}_${type}` })
      }
      await this.getMainDataFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getMainDataFromStore()
    },
    getStatusName(id) {
      return this.statuses.find(status => status.value === id).label
    },
    async onSucceed() {
      await this.getMainDataFromStore()
    },
    async onSearchMainData(action = '') {
      if(action == 'addStudent') {
        await this.getMainDataFromStore()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Thêm sinh viên thành công',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      } else {
        await this.getMainDataFromStore()
      }
    },
    async onApproveSuspend(status) {
      if (this.selectedMainData.length < 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng chọn Sinh viên!',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        return null
      }
      this.isLoading = true
      try {
        const request = {
          studentCgpaIds: this.selectedMainData,
          status,
        }
        const response = await this.updateData(request)
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            await this.getMainDataFromStore()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }

      return null
    },
    exportExcel() {
      if (this.mainData.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Không có dữ liệu để xuất!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      const data = []
      const header = {}
      this.columns.forEach(column => {
        header[column.field] = column.label
      })
      const headerKeys = Object.keys(header)
      const dataSourceKeys = Object.keys(this.mainData[0])
      const duplicates = dataSourceKeys.filter(val => headerKeys.includes(val))
      duplicates.sort((a, b) => headerKeys.indexOf(a) - headerKeys.indexOf(b))
      this.mainData.forEach(dataSource => {
        const elm = {}
        duplicates.forEach(key => {
          elm[header[key]] = dataSource[key]
        })
        data.push(elm)
      })
      const ws = XLSX.utils.json_to_sheet(data)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'SinhVien')
      XLSX.writeFile(wb, 'DanhSachXuLyThoiHoc.xlsx')
    },
  },
}
</script>
